import './../styles/main.scss';
import React from 'react';
import Typography from 'components/ui-components-v2/Typography';
import TemplateHelpers from 'components/data/TemplateHelpers';
import DynamicAsset from 'components/assets/DynamicAsset';

interface Props {
    [key: string]: any;
    bodytext: string;
    headline: string;
    cta: string;
    captionurl: string;
    longbodytext: string;
    longheadline: string;
    advertisername: string;
}

const PreviewDV360 = ({ bodytext, headline, cta, captionurl, longbodytext, longheadline, advertisername, ...other }: Props) => {
    const getAsset = (square: boolean) => {
        const { assetComponents, asset, image } = other;

        // Existing prop
        if (assetComponents) {
            // We have several ones for a dynamic ad
            if (assetComponents[0] && assetComponents[0].length > 1) {
                for (let i = 0; i < assetComponents[0].length; i++) {
                    const formatResult = TemplateHelpers.getFormatDimension(
                        assetComponents[0][i].props.data,
                        assetComponents[0][i].props.originalWidth,
                        assetComponents[0][i].props.originalHeight
                    );

                    if (square && formatResult === '1x1') {
                        if (assetComponents[0][i] && assetComponents[0][i].props.image && Object.keys(assetComponents[0][i].props.image).length > 0) {
                            return assetComponents[0][i];
                        }
                    }
                }
            }

            return assetComponents[0] && assetComponents[0][0] ? assetComponents[0][0] : assetComponents[0];
        }
        // Create in component
        else {
            const item = {
                asset,
                image
            };

            return (
                <DynamicAsset
                    {...item.asset}
                    image={item.image}
                    frameWidth={item.asset && item.asset.frameWidth ? item.asset.frameWidth : 1200}
                    frameHeight={item.asset && item.asset.frameHeight ? item.asset.frameHeight : 620}
                    width={320}
                />
            );
        }
    };
    return (
        <div className="social-preview">
            <div className="social-preview__big-square">
                <div className="social-preview__big-square__advertiser">
                    <img src={other.logo?.url} />
                    <Typography variant="h6">{advertisername}</Typography>
                </div>
                <div className="social-preview__big-square__content">{getAsset(false)}</div>

                <div className="social-preview__big-square__bottom">
                    <Typography variant="h4">{longheadline || headline}</Typography>
                    <Typography variant="body2">{longbodytext || bodytext}</Typography>

                    <div className="social-preview__big-square__bottom__cta-container">
                        <div className="social-preview__big-square__bottom__cta-container__caption">{captionurl}</div>
                        <div className="social-preview__big-square__bottom__cta-container__cta">{cta}</div>
                    </div>
                </div>
            </div>

            <div className="social-preview__square">
                <div className="social-preview__square__content">{getAsset(true)}</div>
                <div>
                    <Typography variant="h4">{longheadline || headline}</Typography>
                    <Typography variant="body2">{longbodytext || bodytext}</Typography>
                </div>
                <div className="social-preview__square__sponsored">
                    Sponsored by
                    <img src={other.logo?.url} />
                </div>
            </div>
        </div>
    );
};

export default PreviewDV360;
